<template>
  <div>
    <table class="table table-striped" >
      <thead>
      <tr>
        <th scope="col">Id</th>
        <th scope="col">Название</th>
        <th scope="col">Провайдер вх.</th>
        <th scope="col">Провайдер исх.</th>
        <th scope="col">Алиас</th>
        <th scope="col">Валюта</th>
        <th scope="col">Баланс</th>
        <th scope="col">Холд</th>
        <th scope="col">Резерв</th>
        <th scope="col">Активна</th>
        <th scope="col">Вериф. карт</th>
        <th scope="col">Вериф. док.</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="ps in allPaymentSystems" v-bind:key="ps.id">
        <td>{{ ps.id }}</td>
        <td><input v-model="ps.name"/></td>
        <td>
          <select v-if="'deposit_provider' in ps.properties" name="providers" v-model="ps.properties.deposit_provider">
            <option value="anymoney">AnyMoney</option>
            <option value="bitcoincore">Bitcoin Core</option>
            <option value="blockcypher">Blockcypher</option>
            <option value="firekassa">Firekassa</option>
            <option value="firekassa_v2">Firekassa V2</option>
            <option value="kuna">Kuna</option>
            <option value="mpcvip">MpcVip</option>
            <option value="perfect_money">Perfect Money</option>
            <option value="skypay">SkyPay</option>
            <option value="trongrid">Trongrid</option>
            <option value="whitebit">Whitebit</option>
            <option value="paylama">PayLama</option>
            <option value="itez">Itez</option>
            <option value="payassist">Payassist</option>
            <option value="payassist-sbp">Payassist SBP</option>
            <option value="etherium">Etherium</option>
            <option value="litecoin">Litecoin</option>
            <option value="monerod">Monerod</option>
            <option value="oko-otc">Oko OTC</option>
            <option value="wallex">Wallex</option>
            <option value="paycraft">Paycraft</option>
            <option value="payfinity">Payfinity</option>

            <option value="test">пусто</option>
          </select>
          <select v-else name="providers" disabled>
          </select>
        </td>
        <td>
          <select v-if="'withdrawal_provider' in ps.properties" name="providers" v-model="ps.properties.withdrawal_provider">
            <option value="anymoney">AnyMoney</option>
            <option value="bitcoincore">Bitcoin Core</option>
            <option value="blockcypher">Blockcypher</option>
            <option value="firekassa">Firekassa</option>
            <option value="firekassa_v2">Firekassa V2</option>
            <option value="kuna">Kuna</option>
            <option value="mpcvip">MpcVip</option>
            <option value="perfect_money">Perfect Money</option>
            <option value="skypay">SkyPay</option>
            <option value="trongrid">Trongrid</option>
            <option value="whitebit">Whitebit</option>
            <option value="paylama">PayLama</option>
            <option value="payassist">Payassist</option>
            <option value="payassist-sbp">Payassist SBP</option>
            <option value="etherium">Etherium</option>
            <option value="litecoin">Litecoin</option>
            <option value="monerod">Monerod</option>
            <option value="oko-otc">Oko OTC</option>
            <option value="wallex">Wallex</option>
            <option value="paycraft">Paycraft</option>
            <option value="payfinity">Payfinity</option>

            <option value="test">пусто</option>
          </select>
          <select v-else name="providers" disabled>
          </select>
        </td>
        <td>{{ ps.alias }}</td>
        <td>{{ ps.currency.isoCode }}</td>
        <td>{{ ps.balance }}</td>
        <td><input v-model="ps.hold" style="width: 100px"/></td>
        <td>{{ ps.reserve }}</td>
        <td><input  v-model="ps.enabled" class="form-check-input" type="checkbox"></td>
        <td><input  v-model="ps.needVerification" class="form-check-input" type="checkbox"></td>
        <td><input  v-model="ps.needDocsVerification" class="form-check-input" type="checkbox"></td>
      </tr>
      </tbody>
    </table>
    <button :disabled="saveButtonDisabled" class="btn btn-success mt-4" type="button" v-on:click="save">Сохранить
    </button>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
  name: 'PaymentSystems',

  data: function () {
    return {
      saveButtonDisabled: false
    }
  },

  computed: {
    ...mapGetters(['allPaymentSystems']),
  },

  methods: {
    ...mapActions(["fetchPaymentSystems", "updatePaymentSystems"]),
    save: async function () {
      this.saveButtonDisabled = true
      await this.updatePaymentSystems()
      this.saveButtonDisabled = false

    }
  },

  mounted() {
    this.fetchPaymentSystems()
  }
}
</script>